import { ProgressSpinner } from "primereact/progressspinner";
import { Toast } from "primereact/toast";
import { useEffect, useRef, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useAuthState } from "../../context/context";
import UserService from "../../services/userService";
import EntrepriseService from "../../services/entrepriseService";
import StripeService from "../../services/stripeService";
import { PersonnalInfoCompany } from "./formProfileComponents/company/personnalInfoCompany";
import "./formProfile.css"
import "./formCompanyProfile.css"
import { enumRoles } from "../../enums/enumRoles";
import { Button } from "primereact/button";
import { CompanyInfo } from "./formProfileComponents/company/companyInfo";
import { TabPanel, TabView } from "primereact/tabview";
import { useHistory } from 'react-router-dom';
import { Dialog } from "primereact/dialog";
import { CompanyChooseSubscription } from "../subscription/companyChooseSubscription";
import RoleHelper from "../../utils/roleHelper";
import { DtFactures } from "../datatable/DtFactures";
import { DT_gestionCollaborateurEntreprise } from "../datatable/dt_gestionCollaborateurEntreprise";

export const FormCompanyProfile = (props) => {

    const [submitted, setSubmitted] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    // const [siretIsValid, setSiretIsValid] = useState(false)
    const [recruteur, setRecruteur] = useState(null);

    const { t } = useTranslation()
    const history = useHistory();
    const toast = useRef(null)
    const currentUser = useAuthState()
    const userService = new UserService()
    const entrepriseService = new EntrepriseService()
    const stripeService = new StripeService()
    const [loading, setLoading] = useState(true)
    const [displayDialog, setDisplayDialog] = useState(false);
    const [activeIndex, setActiveIndex] = useState(0);
    const adminImpersonatingRecruteurId = history.location.state?.adminImpersonatingRecruteurId

    useEffect(() => {
        loadData()
    }, [])

    const loadData = () => {
        setLoading(true);
        if (history.location.state === "firstConnection")
            setDisplayDialog(true);

        if (history.location.hash === "#abonnement")
            setActiveIndex(2);

        entrepriseService
            .getExtendEntrepriseProfil(adminImpersonatingRecruteurId)
            .then(async (_recruteur) => {
                // on vérifie si l'entreprise possède un code promo
                setRecruteur(_recruteur);
                await searchPartenariatInfo(_recruteur);
            })
            .catch((err) => {
                toast.current.show({ severity: 'error', summary: t('errors.error'), detail: t('errors.cant_load'), life: 3000 })
            })
            .finally(() => {
                setLoading(false);
            })
    }

    const searchPartenariatInfo = async (_recruteur) => {
        // temporary code for promotion code
        let codePartenariat = _recruteur.recruteurEntreprises[0].entreprise.codePartenariat;
        if(codePartenariat === "" || codePartenariat === null){
            _recruteur.recruteurEntreprises[0].entreprise.codePartenariat = "Decouverte";
        }
        if (_recruteur.recruteurEntreprises[0]?.entreprise.codePartenariat) {
            await stripeService.getPartenariatInfo(_recruteur.recruteurEntreprises[0].entreprise.codePartenariat)
                .then((_codePromo) => {
                    if (_recruteur.dateSouscription) {
                        // Calculer la date d'échéance du coupon
                        const expirationDate = new Date(_recruteur.dateSouscription);
                        expirationDate.setMonth(expirationDate.getMonth() + _codePromo.value.durationInMonths);
                        // Comparer la date d'échéance avec la date actuelle
                        const couponIsStillValid = expirationDate > new Date();
                        if (couponIsStillValid){
                            _recruteur.recruteurEntreprises[0].entreprise.codePromo = _codePromo.value;
                        }
                        else
                            _recruteur.recruteurEntreprises[0].entreprise.codePromo = null;
                    }
                    else {
                        _recruteur.recruteurEntreprises[0].entreprise.codePromo = _codePromo.value;
                    }
                    setRecruteur(_recruteur);
                })
                .catch((err) => {
                    _recruteur.recruteurEntreprises[0].entreprise.codePromo = null;
                    setRecruteur(_recruteur);
                })
        }
    }

    const onInputRecruteurChange = (e, name) => {
        let val = (e.value || e.target.value) || '';
        let _recruteur = { ...recruteur };
        _recruteur[`${name}`] = val;
        // if (name == 'siret') {
        //     setSiretIsValid(false);
        //     _entreprise.nomEntreprise = null;
        //     _entreprise.secteurActivite = null;
        //     _entreprise.localisation = null;
        //     _entreprise.taille = null;
        // }
        setRecruteur(_recruteur);
    };

    const onInputEntrepriseChange = (e, name) => {
        let val = (e.value || e.target.value) || '';
        let _recruteur = { ...recruteur };
        _recruteur.recruteurEntreprises[0].entreprise[`${name}`] = val;
        if(name=='commune'){
            _recruteur.recruteurEntreprises[0].entreprise.communeId = val?.id;
        }
        // if (name == 'siret') {
        //     setSiretIsValid(false);
        //     _entreprise.nomEntreprise = null;
        //     _entreprise.secteurActivite = null;
        //     _entreprise.localisation = null;
        //     _entreprise.taille = null;
        // }
        setRecruteur(_recruteur);
    };

    const onInputRecruteurRBChange = (e, name) => {
        let _recruteur = { ...recruteur };
        _recruteur[`${name}`] = e;
        setRecruteur(_recruteur);
    };

    const onWysiwygChange = (content, editor) => {
        let _recruteur = { ...recruteur };
        _recruteur.recruteurEntreprises[0].entreprise.description = content;
        setRecruteur(_recruteur);
    }

    const onEtrangerChange = (e, name) => {
        let _recruteur = { ...recruteur };
        let val = (e.checked) || false;
        _recruteur.recruteurEntreprises[0].entreprise.etranger = val;
        _recruteur.recruteurEntreprises[0].entreprise.lieuResidence = null;
        _recruteur.recruteurEntreprises[0].entreprise.pays = null;
        _recruteur.recruteurEntreprises[0].entreprise.commune = null;
        setRecruteur(_recruteur);
    };

    const majCompte = () => {
        setSubmitted(true);
        if (recruteur.prenom &&
            recruteur.nom &&
            recruteur.email &&
            recruteur.phoneNumber &&
            recruteur.recruteurEntreprises[0]?.entreprise?.nom &&
            (recruteur.recruteurEntreprises[0]?.entreprise?.siret?.length == 14 || !recruteur.recruteurEntreprises[0]?.entreprise?.siret)
            //&& siretIsValid
        ) {
            setIsSubmitting(true);

            // avant modification de l'entreprise, on cherche si l'entreprise a déjà complété son profil
            let RegistrationOnboardingCompleted = true;
            userService.getEntreprise(currentUser.user.id)
                .then((_entreprise) => {
                    RegistrationOnboardingCompleted = _entreprise.isRegistrationOnboardingCompleted;
                })

            recruteur.IsRegistrationOnboardingCompleted = true;
            userService.updateEntreprise(currentUser, recruteur)
                .then((_entreprise) => {
                    setRecruteur(_entreprise);
                    searchPartenariatInfo(_entreprise);

                    setSubmitted(false);
                    toast.current.show({ severity: 'success', summary: t('general.success'), detail: t('profile.account_updated'), life: 3000 });
                    if (!RegistrationOnboardingCompleted) {
                        // une fois le profil complété, on load la page afin de recharger les données, on redirige ensuite vers la page des abonnements
                        setLoading(true);
                        setActiveIndex(2);
                        // history.push({ pathname: '/offers', firstConnection : true });
                    }
                })
                .catch(err => {
                    toast.current.show({ severity: 'error', summary: t('errors.error'), detail: t('errors.cant_save'), life: 3000 })
                })
                .finally(() => {
                    setIsSubmitting(false);
                    if (!RegistrationOnboardingCompleted) {
                        setLoading(false);
                    }
                })
        }
        else {
            toast.current.show({ severity: 'error', summary: t('errors.incomplete_summary'), detail: t('errors.incomplete_detail'), life: 3000 })
        }
    }

    const onPortalClick = () => {
        window.open(window.STRIPE_PORTAL_URL, '_blank')
    }

    return (
        <div className={props.className}>
            <Dialog className="fcp-popup-first-connection" header={t('profile.first_connection_title')} visible={displayDialog} style={{ width: '50vw' }} onHide={() => setDisplayDialog(false)}>
                <div className="p-ml-3 p-mr-4">
                    <Trans i18nKey="profile.first_connection_message" />
                </div>
                <div className='p-d-flex p-jc-end p-mt-2'>
                    <Button label={t('general.ok')} className="p-mt-4 fcp-button-popup-first-connection" onClick={() => setDisplayDialog(false)} />
                </div>
            </Dialog>
            <Toast ref={toast} />
            {loading ? (
                <div className="fsp-loading">
                    <ProgressSpinner />
                </div>
            ) : (
                <>
                    <TabView activeIndex={activeIndex} className="fcp-tab">
                        <TabPanel header={t('general.myProfile.full')}>
                            <div className={`form-entreprise-theme ${isSubmitting && 'fsp-disabled'}`}>
                                <PersonnalInfoCompany
                                    recruteur={recruteur}
                                    setRecruteur={setRecruteur}
                                    onInputChange={onInputRecruteurChange}
                                    onInputRBChange={onInputRecruteurRBChange}
                                    submitted={submitted}
                                />
                                <br />
                                <CompanyInfo
                                    entreprise={recruteur.recruteurEntreprises[0]?.entreprise}
                                    isDisabled={recruteur.recruteurEntreprises[0]?.isManager == false}
                                    // siretIsValid={siretIsValid}
                                    // setSiretIsValid={setSiretIsValid}
                                    onInputChange={onInputEntrepriseChange}
                                    onWysiwygChange={onWysiwygChange}
                                    onEtrangerChange={onEtrangerChange}
                                    submitted={submitted}
                                />
                                <div className='p-d-flex p-jc-end'>
                                    <Button label={t('general.save')} icon="pi pi-save" className="p-mt-2 frs-button" onClick={majCompte} />
                                </div>
                            </div>
                        </TabPanel>
                        
                        <TabPanel header={t('entreprise.collaborator.my_collaborators')}>
                            <DT_gestionCollaborateurEntreprise entreprise={recruteur.recruteurEntreprises[0]?.entreprise} />
                        </TabPanel>
                        <TabPanel header={t('general.mySubscription')}>
                            <CompanyChooseSubscription entreprise={recruteur.recruteurEntreprises[0]?.entreprise} />
                        </TabPanel>
                        {/* <TabPanel header={t('general.myPaymentDetails')}>
                            <div className="dip-ta-center p-mt-5">
                                <Button className="dip-btn-red-bg" label={t('general.go_to_portal')} onClick={onPortalClick} />
                            </div>
                        </TabPanel> */}
                        <TabPanel header={t('general.myInvoices')}>
                            <DtFactures />
                        </TabPanel>
                    </TabView>
                </>
            )}
        </div>
    )
}