import { Divider } from "primereact/divider"
import logo from "../../logo.png"
import { IoBusinessOutline } from 'react-icons/io5';
import { IoCall } from 'react-icons/io5';
import { IoMailOutline } from 'react-icons/io5';
import { IoLogoLinkedin } from 'react-icons/io5';
import { IoLogoFacebook } from 'react-icons/io5';
import { IoLogoInstagram } from 'react-icons/io5';
import { Link, useHistory } from 'react-router-dom';
import { enumRoles } from "../../enums/enumRoles";
import "./footer.css"
import { Trans, useTranslation } from "react-i18next";
import { Mailto } from "../mailto/mailto";
import { Tel } from "../tel/tel";


export const Footer = (props) => {

    const { t } = useTranslation();
    const history = useHistory();

    const onInscriptionEtudiantClick = () => {
        history.push({ pathname: '/register?User=Student', state :{ userType : enumRoles.ETUDIANT, previousUrl : history.location.pathname + history.location.search }});
    }

    const onInscriptionEntrepriseClick = () => {
        history.push({ pathname: '/register?User=Company', state :{ userType : enumRoles.ENTREPRISE, previousUrl : history.location.pathname + history.location.search }});
    }
    const onLinkedinClick = () => {
        var win = window.open("https://www.linkedin.com/company/diplomadvisor")
        win.focus();
    }

    const onFacebookClick = () => {
        var win = window.open("https://www.facebook.com/diplomadvisor/")
        win.focus();
    }

    const onInstagramClick = () => {
        var win = window.open("https://www.instagram.com/diplomadvisor/")
        win.focus();
    }

    const onPDCClick = () => {
        history.push("/confidentiality");
    }

    const onCookiesClick = () => {
        props.setCookieRefresh(new Date());
    }

    const onGuaraniClick = () => {
        var win = window.open("https://www.guarani.fr")
        win.focus();
    }

    return (
        <div className={(`dip-bg-color-${history.location.pathname.toLowerCase() == "/entreprise" ? 'res-blue' : (props.theme ? props.theme : "eb")}`)}>
            <div className="app-width-limit">
                <div className="footer-main-container">
                    <div className="footer-logo-container">
                        <img src={logo} alt={"logo DiplomAdvisor"}/>
                    </div>
                    <div className="footer-over-divider-container">
                        <div  vocab="https://schema.org/" typeof="Organization" className="footer-info-container">
                            <div className="footer-logo-info-line">
                                <div className="p-mr-2">
                                    <IoBusinessOutline />
                                </div>
                                <div property="address" typeof="PostalAddress">
                                    <div property="streetAddress">Siège : Campus Azur, 250 Rue Albert Einstein</div>
                                    <div>Bât. 2. Laboratoire GREDEG</div>
                                    <div><span property="postalCode">06560</span> <span property="addressLocality">Valbonne, France</span></div>
                                    <br />
                                    <div>DiplomAdvisor SAS</div>
                                    <div>Siret : 89489743800012</div>
                                    <div>RCS : Grasse B 894 897 438</div>
                                    <div>Capital social : 10 000,00 €</div>
                                </div>
                            </div>
                            <div className="footer-logo-info-line">
                                <div className="p-mr-2">
                                    <IoCall />
                                </div>
                                <div>
                                    <Tel 
                                        property="telephone"
                                        label="06 60 40 73 08"
                                        className="footer-clickable-link"
                                        to="0033660407308"
                                    />
                                </div>
                            </div>
                            <div className="footer-logo-info-line">
                                <div className="p-mr-2">
                                    <IoMailOutline />
                                </div>
                                <div><Mailto
                                    property="email"
                                    label="contact@diplomadvisor.com"
                                    className="footer-clickable-link"
                                    object=""
                                    body=""
                                    to="contact@diplomadvisor.com" />
                                </div>
                            </div>
                        </div>
                        <div>
                            <div>
                                <Link to={{pathname: "/register?User=Student", state:{userType : enumRoles.ETUDIANT, previousUrl : history.location.pathname + history.location.search} }} className="footer-clickable-link">{t('footer.student_register')}</Link>
                            </div>
                            <div>
                                <Link to={{pathname:"/register?User=Company",  state:{userType : enumRoles.ENTREPRISE, previousUrl : history.location.pathname + history.location.search} }} className="footer-clickable-link">{t('footer.company_register')}</Link>
                            </div>
                            <div>
                                <Mailto
                                    label={t('footer.school_register')}
                                    className="footer-clickable-link"
                                    object={t('footer.school_mail_object')}
                                    body={encodeURIComponent(t('footer.school_mail_body'))}
                                    to="contact@diplomadvisor.com"
                                />
                            </div>

                        </div>
                        <div className="footer-socials-container">
                            <div className="p-mr-4" >
                                <a className="footer-clickable-link" rel="nofollow" href="https://www.linkedin.com/company/diplomadvisor" target="_blank"><IoLogoLinkedin/></a>
                            </div>
                            <div className="p-mr-4" >
                                <a className="footer-clickable-link" rel="nofollow" href="https://www.facebook.com/diplomadvisor/" target="_blank"><IoLogoFacebook/></a>
                            </div>
                            <div >
                                <a className="footer-clickable-link" rel="nofollow" href="https://www.instagram.com/diplomadvisor/" target="_blank"><IoLogoInstagram/></a>
                            </div>
                        </div>
                    </div>
                    <Divider className="footer-divider" />
                    <div className="footer-below-divider-container">
                        <div className="footer-document-container">
                            <div className="p-pr-4"><Link to="/pdf/CGU_DiplomAdvisor.pdf" rel="nofollow" target="_blank" className={'footer-clickable-link'} download>{t('footer.cgu')}</Link></div>
                            <div><Link className={'footer-clickable-link p-pr-4'} to="/confidentiality" rel="nofollow">{t('footer.pc')}</Link></div>
                            <div><span className={'footer-clickable-link'} onClick={(e) => onCookiesClick()}>{t('footer.cookies')}</span></div>
                        </div>
                        <div>© {new Date().getFullYear()} DiplomAdvisor - Créé par <a className="footer-creator footer-clickable-link" href="https://www.guarani.fr/">Guarani SAS</a></div>
                    </div>
                </div>
            </div>
        </div>
    )
}