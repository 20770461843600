import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { FormRegister } from "../../components/form/formRegister";
import "./landingPageEntreprise.css";
import { Trans, useTranslation } from "react-i18next";

export const LandingPageEntreprise = () => {
  const images = [
    {
      img: "https://vbbyzkwiki2m9i1q.public.blob.vercel-storage.com/240_F_249777683_ZxXAzDiCZQhOHYAtDtiSIwHpdZnIKIRj-KeMls3pkrnjuNg0rc5hAxNrirl96Dd.jpg",
      hoverImg:
        "https://vbbyzkwiki2m9i1q.public.blob.vercel-storage.com/240_F_251063662_FkQ3Yt6vybIfqvXFUXHiRcMjHgTZKWdQ-aTTkW8PGYx21zcZ8erpLOLkx6vXxyz.jpg",
      alt: "Étudiant avec des livres",
    },
    {
      img: "https://vbbyzkwiki2m9i1q.public.blob.vercel-storage.com/240_F_272619987_mbt2Yl4bMQu7ZYgJjbtFHqDwQIPvCAvp-idvQGiVO5bf0OkuUd12GxQXokyHVuI.jpg",
      hoverImg:
        "https://vbbyzkwiki2m9i1q.public.blob.vercel-storage.com/240_F_275145513_BGXVojwLqVr1gVhmO1YG2Qf1Kh2hqies-VLnoo908Zz0PgAtwtFpcbeHtqFWy3H.jpg",
      alt: "Étudiante avec ordinateur portable",
    },
    {
      img: "https://vbbyzkwiki2m9i1q.public.blob.vercel-storage.com/240_F_339602256_dBq6bYfHWzlnRQkXgAMFXZfx2r1DPQns-3vTGFAIPUksFMn5OOeZ2Vi7s86Z50L.jpg",
      hoverImg:
        "https://vbbyzkwiki2m9i1q.public.blob.vercel-storage.com/240_F_326977874_aXtSm081zzZHicnRDzbPdbJDP9WJqYIG-BdeHj0RdCcBvlHVJ60t2OQdwf5S1tw.jpg",
      alt: "Étudiant avec un ordinateur portable",
    },
    {
      img: "https://vbbyzkwiki2m9i1q.public.blob.vercel-storage.com/jeune-homme-souriant-elegant-tenant-haut-parleur-sans-fil-ecoutant-musique_285396-9505-svnExopPuwiGAhA6isqMA4qUzZM4Qj.jpg",
      hoverImg:
        "https://vbbyzkwiki2m9i1q.public.blob.vercel-storage.com/240_F_484907348_9tuG9pmOPPfPRnwCB06H9zoJgT5D8UVF-91ZVDJAgyD01Icpi7NbKdKYy3c3ZZ4.jpg",
      alt: "Jeune homme souriant avec un haut-parleur sans fil",
    },
  ];

  const steps = [
    {
      step: 1,
      title: "Publiez des offres d'emploi",
      description:
        "Diffusion ciblée vers les profils les plus pertinents. Recevez uniquement des candidatures qualifiées.",
    },
    {
      step: 2,
      title: "Explorez notre CVthèque",
      description:
        "Utilisez notre moteur de recherche pour trouver les profils les plus adaptés et contactez-les immédiatement.",
    },
    {
      step: 3,
      title: "Soyez alerté",
      description:
        "N'attendez plus le dernier moment pour recruter. Définissez vos critères et recevez une alerte dès l'apparition du profil idéal.",
    },
  ];

  const testimonials = [
    {
      name: "Anthony Mareschi",
      role: "Fondateur, Rekto",
      quote:
        "DiplomAdvisor nous a permis d'améliorer considérablement l'efficacité de notre sourcing. Quel gain de temps !"},
    {
      name: "Yann Boyer-Durand",
      role: "DG, SAS BDG",
      quote:
        "Enfin une plateforme qui nous permet d'avoir accès facilement à des profils juniors qualifiés ! Le moteur de recherche par compétences est particulièrement utile."},
    {
      name: "Alan T.",
      role: "Alternant ingénieur en conception mécanique",
      quote:
        "Merci pour votre aide ! Je n'aurais probablement pas pu trouver cette offre de contrat d'alternance sans DiplomAdvisor"},
  ];

  const fadeIn = (delay = 0) => ({
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.5, delay } },
  });

  const [inViewSteps, setInViewSteps] = useState(false);
  const [inViewPricing, setInViewPricing] = useState(false);
  const [inViewTestimonials, setInViewTestimonials] = useState(false);
  const [dialogVisible, setDialogVisible] = useState(false);
  const [visibleHubSpot, setVisibleHubSpot] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    // Charger le script HubSpot une fois que la popup est visible
    if (visibleHubSpot) {
      const script1 = document.createElement("script");
      script1.src = "//js.hsforms.net/forms/embed/v2.js";
      script1.charset = "utf-8";
      script1.type = "text/javascript";
      script1.onload = () => {
        if (window.hbspt) {
          window.hbspt.forms.create({
            portalId: "20427377",
            formId: "84b22816-0ad9-4fdb-95a7-2cdf8b540111",
            target: "#hubspot-form",
            onFormSubmit: () => {
              setFormSubmitted(true); // Activer le bouton après la soumission
            },
          });
        }
      };

      document.body.appendChild(script1);

      // Nettoyer le script lorsque le composant est démonté
      return () => {
        document.body.removeChild(script1);
      };
    }
  }, [visibleHubSpot]);

  useEffect(() => {
    const observerSteps = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setInViewSteps(true);
        }
      },
      { threshold: 0.2 }
    );

    const observerPricing = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setInViewPricing(true);
        }
      },
      { threshold: 0.2 }
    );

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setInViewTestimonials(true);
        }
      },
      { threshold: 0.2 }
    );

    const stepsSection = document.querySelector("#steps-section");
    const pricingSection = document.querySelector("#pricing-section");
    const testimonialsSection = document.querySelector("#testimonials-section");

    if (stepsSection) observerSteps.observe(stepsSection);
    if (pricingSection) observerPricing.observe(pricingSection);
    if (testimonialsSection) observer.observe(testimonialsSection);

    return () => {
      observerSteps.disconnect();
      observerPricing.disconnect();
      observer.disconnect();
    };
  }, []);

  return (
    <div className="header-margin-compensator">
      <Dialog header={<div className="lpe-custom-dialog-header">Créez gratuitement votre compte</div>} visible={dialogVisible} onHide={() => setDialogVisible(false)}>
        <FormRegister userType="Entreprise" />
      </Dialog>
      <Dialog
        visible={visibleHubSpot}
        style={{ width: "50vw" }}
        onHide={() => setVisibleHubSpot(false)}
      >
        <div id="hubspot-form"></div>
        {formSubmitted && (
          <div className="">
            <Button
              label="Allons-y !"
              className="pricing-button hubspot-button"
              onClick={() => {
                setVisibleHubSpot(false);
                setFormSubmitted(false);
                setDialogVisible(true);
              }}
            />
          </div>
        )}
      </Dialog>
      <section className="landing-page">
        <motion.section
          className="lpe-section section-wrapper"
          initial="hidden"
          animate="visible"
          variants={fadeIn(0)}
        >
          <div className="background-image"></div>
          <div className="background-overlay"></div>
          <div className="content-wrapper container">
            <div className="content">
              <motion.h1 className="lpe-title main-title" variants={fadeIn(0)}>
                <Trans i18nKey="company.interns_apprentices" />
                <br />
                <Trans i18nKey="company.young_graduates" />
              </motion.h1>
              <motion.p className="description" variants={fadeIn(0.2)}>
                <strong className="highlight-text">
                  <Trans i18nKey="company.recruit_easily_description" />
                  <br />
                  <Trans i18nKey="company.more_than_20k_candidates" />
                </strong>
              </motion.p>
              <motion.div className="button-group" variants={fadeIn(0.4)}>
                <Button
                  label={t("company.publish_your_job")}
                  className="cta-button"
                  icon="pi pi-arrow-right"
                  iconPos="right"
                  onClick={() => setDialogVisible(true)}
                />
              </motion.div>
              <motion.p className="helper-text" variants={fadeIn(0.6)}>
                <Trans i18nKey="company.we_help_recruit_candidate" />
              </motion.p>
              <motion.div className="image-grid" variants={fadeIn(0.8)}>
                {images.map((item, index) => (
                  <motion.div
                    className="image-wrapper"
                    key={index}
                    initial="hidden"
                    animate="visible"
                    variants={fadeIn(index * 0.2)}
                  >
                    <img src={item.img} alt={item.alt} className="default-image" />
                    <img
                      src={item.hoverImg}
                      alt={`Hover ${item.alt}`}
                      className="hover-image"
                    />
                  </motion.div>
                ))}
              </motion.div>
              <motion.div className="info-card" variants={fadeIn(1)}>
                <div>
                  <div className="card-header">
                    <h3 className="info-title"><Trans i18nKey="company.why_diplomadvisor" /></h3>
                  </div>
                  <ul className="info-list">
                    <li>
                      <i className="pi pi-search info-icon"></i>
                      <Trans i18nKey="company.more_than_25k_formations" />
                    </li>
                    <li>
                      <i className="pi pi-users info-icon"></i>
                      <Trans i18nKey="company.diversified_talent_pool" />
                    </li>
                    <li>
                      <i className="pi pi-briefcase info-icon"></i>
                      <Trans i18nKey="company.simplified_recruitment_process" />
                    </li>
                  </ul>
                  <Button
                    label={t("company.book_a_demo")}
                    icon="pi pi-external-link"
                    iconPos="right"
                    className="outline-button"
                    onClick={() =>
                      window.open("https://meetings.hubspot.com/patrick-musso/demo-da?uuid=732ac826-a453-4cc3-9bf5-10378fe9fc9f", "_blank")
                    }
                  />
                </div>
              </motion.div>
            </div>
          </div>
        </motion.section>
      </section>

      <section id="steps-section" className="lpe-section how-it-works-section bg-white dark:bg-gray-800">
        <div className="container">
          <motion.h2
            className="lpe-title section-title"
            initial={{ opacity: 0 }}
            animate={inViewSteps ? { opacity: 1 } : {}}
            transition={{ duration: 1 }}
          >
            <Trans i18nKey="company.how_it_works" />
          </motion.h2>
          <motion.p
            className="section-description"
            initial={{ opacity: 0 }}
            animate={inViewSteps ? { opacity: 1 } : {}}
            transition={{ duration: 1, delay: 0.2 }}
          >
            <Trans i18nKey="company.three_ways_to_recruit" />
          </motion.p>
          <div className="steps-grid">
            {steps.map((item, index) => (
              <motion.div
                key={index}
                className="step-card"
                initial={{ opacity: 0, y: 50 }}
                animate={inViewSteps ? { opacity: 1, y: 0 } : {}}
                transition={{ duration: 1, delay: index * 0.2 }}
              >
                <div className="step-circle">{item.step}</div>
                <h3 className="step-title">{item.title}</h3>
                <p className="step-description">{item.description}</p>
              </motion.div>
            ))}
          </div>
          <motion.div
            className="cta-container"
            initial={{ opacity: 0, scale: 0.8 }}
            animate={inViewSteps ? { opacity: 1, scale: 1 } : {}}
            transition={{ duration: 1, delay: 0.6 }}
          >
          <Button 
            label={t("company.start_recruiting")}
            className="cta-button" 
            icon="pi pi-arrow-right"
            iconPos="right"
            onClick={() => setDialogVisible(true)}
          />
        </motion.div>
        </div>
      </section>

      <section id="pricing-section" className="lpe-section pricing-section">
        <div className="pricing-container">
          {/* Texte principal et bouton */}
          <motion.div
            className="pricing-content"
            initial={{ opacity: 0, x: -50 }}
            animate={inViewPricing ? { opacity: 1, x: 0 } : {}}
            transition={{ duration: 1 }}
          >
            <h2 className="lpe-title pricing-title"><Trans i18nKey="company.how_much_it_cost" /></h2>
            <p className="pricing-description">
              <Trans i18nKey="company.transparent_pricing" />
            </p>
            <div className="pricing-features">
              <div className="pricing-feature">
                <img
                  src="https://vbbyzkwiki2m9i1q.public.blob.vercel-storage.com/contract_10787148-0aAM6qf8Qti0dPpZYyS3Qwc8GUzdUH.png"
                  alt="Offre Freemium"
                  className="pricing-icon"
                />
                <p>
                  <strong><Trans i18nKey={t("subscription.offers.offer_freemium")} /></strong> :
                  <Trans i18nKey="company.first_description_landing_page" />
                </p>
              </div>
              <div className="pricing-feature">
                <img
                  src="https://vbbyzkwiki2m9i1q.public.blob.vercel-storage.com/investment_15636839-cT1DKBUNZxfrCdeWzgIWvX16GGBCZU.png"
                  alt="Offres Premium"
                  className="pricing-icon"
                />
                <p>
                  <strong><Trans i18nKey={t("subscription.offers.offer_premium")} /></strong> : 
                  <Trans i18nKey="company.second_description_landing_page" />
                </p>
              </div>
              <div className="pricing-feature">
                <img
                  src="https://vbbyzkwiki2m9i1q.public.blob.vercel-storage.com/opportunities_15637002-CpZf8iNe1R3TCYzqL84rTicxDW4ozM.png"
                  alt="Offres adaptées"
                  className="pricing-icon"
                />
                <p>
                  <strong><Trans i18nKey={"subscription.offers.offers_adapted_activity"} /></strong> : 
                  <Trans i18nKey="company.third_description_landing_page" />
                </p>
              </div>
              <p className="pricing-note">
                <Trans i18nKey="company.note_pricing" />
              </p>
            </div>
            <motion.div
              className="pricing-button-wrapper"
              initial={{ opacity: 0, scale: 0.8 }}
              animate={inViewPricing ? { opacity: 1, scale: 1 } : {}}
              transition={{ duration: 1, delay: 1 }}
            >
              <Button
                label={t("company.note_pricing_button")}
                className="pricing-button"
                onClick={() => setVisibleHubSpot(true)}
              />
            </motion.div>
          </motion.div>

          {/* Image */}
          <motion.div
            className="pricing-image-wrapper"
            initial={{ opacity: 0, x: 50 }}
            animate={inViewPricing ? { opacity: 1, x: 0 } : {}}
            transition={{ duration: 1, delay: 0.5 }}
          >
            <img
              src="https://vbbyzkwiki2m9i1q.public.blob.vercel-storage.com/iStock-1930902406-M021l1pJfnl8QG4PGTD0AWX0JXuoIn.jpg"
              alt="Illustration des tarifs"
              className="pricing-image"
            />
          </motion.div>
        </div>
      </section>

      <section id="testimonials-section" className="lpe-section testimonials-section">
        <div className="testimonials-container">
          <motion.h2
            className="lpe-title testimonials-title"
            initial={{ opacity: 0, y: 50 }}
            animate={inViewTestimonials ? { opacity: 1, y: 0 } : {}}
            transition={{ duration: 1 }}
          >
            <Trans i18nKey="company.testimonials_title" />
          </motion.h2>
          <div className="testimonials-grid">
            {testimonials.map((testimonial, index) => (
              <motion.div
                key={index}
                className="testimonial-card"
                initial={{ opacity: 0, y: 50 }}
                animate={inViewTestimonials ? { opacity: 1, y: 0 } : {}}
                transition={{ duration: 0.5, delay: index * 0.2 }}
              >
                <div className="testimonial-image-wrapper">
                  <img
                    src={
                      testimonial.name === "Anthony Mareschi"
                        ? "https://vbbyzkwiki2m9i1q.public.blob.vercel-storage.com/1718315189472-ketGJ11GfW4OvIE8gQLc4jXYHF5Ojc.jpeg"
                        : testimonial.name === "Yann Boyer-Durand"
                        ? "https://vbbyzkwiki2m9i1q.public.blob.vercel-storage.com/unnamed-cTtjGl5a3LPEAez7sdgadyM6dsDre2.jpg"
                        : "https://vbbyzkwiki2m9i1q.public.blob.vercel-storage.com/Veolia-M4Zq0m2Lb3RPbKQer1P6P5gh1XGlky.jpg"
                    }
                    alt={`Témoignage de ${testimonial.name}`}
                    className="testimonial-image"
                  />
                </div>
                <div className="testimonial-content">
                  <p className="testimonial-name">{testimonial.name}</p>
                  <p className="testimonial-role">{testimonial.role}</p>
                  <p className="testimonial-quote">"{testimonial.quote}"</p>
                </div>
              </motion.div>
            ))}
          </div>
          <motion.div
            className="testimonials-button-wrapper"
            initial={{ opacity: 0, scale: 0.8 }}
            animate={inViewTestimonials ? { opacity: 1, scale: 1 } : {}}
            transition={{ duration: 1, delay: 0.6 }}
          >
            <Button
              label={t("company.testimonials_button")}
              className="testimonials-button"
              onClick={() => setDialogVisible(true)}
            />
          </motion.div>
        </div>
      </section>
    </div>
  );
};